import { usePathname } from 'next/navigation';

import { ComponentType, useMemo } from 'react';

import ImageProxy from '@next-image-proxy';

import { STORE_PAGE_ID, StorePageId } from '@api';

import { Flame } from '@uikit/icons/Flame';
import { MultiColorCompass } from '@uikit/icons/MultiColorCompass';
import { MultiColorDownload } from '@uikit/icons/MultiColorDownload';
import { MultiColorGamepad } from '@uikit/icons/MultiColorGamepad';
import { MultiColorGift2 } from '@uikit/icons/MultiColorGift2';
import { MultiColorUpcoming } from '@uikit/icons/MultiColorUpcoming';

import { BreadcrumbsItem } from '@store:web/components/common/Breadcrumbs/Breadcrumbs';
import BasePageHeader, {
  BasePageHeaderProps,
} from '@store:web/components/layout/PageHeader/BasePageHeader';
import { roadToTgeQuests } from '@store:web/components/pages/StoryPage/hardcodedStories/roadToTgeQuests';

type PageWithHeaderId =
  | StorePageId
  | 'hot-offers'
  | 'magic-board'
  | 'perks'
  | 'tge'
  | 'user'
  | 'reward-pools';

const pageHeaderProps: Record<
  PageWithHeaderId,
  BasePageHeaderProps & { Icon?: ComponentType<{ className?: string }> }
> = {
  [STORE_PAGE_ID.discover]: {
    title: 'Discover: Explore the Magic Store Web3 App Store',
    Icon: MultiColorCompass,
    decorImage: {
      mobile: '/page-header/discover-page-banner-decor-mobile.png',
      desktop: '/page-header/discover-page-banner-decor-desktop.png',
    },
    breadcrumbs: [
      {
        label: 'Discover',
        pathname: '/',
      },
    ],
  },
  [STORE_PAGE_ID.apps]: {
    title: 'Apps: Start a Web3 Journey - DeFi, CeFi, Wallets, & More',
    Icon: MultiColorDownload,
    decorImage: {
      mobile: '/page-header/apps-page-banner-decor-mobile.png',
      desktop: '/page-header/apps-page-banner-decor-desktop.png',
    },
    breadcrumbs: [
      {
        label: 'Discover',
        pathname: '/',
      },
      {
        label: 'Apps',
        pathname: '/apps',
      },
    ],
  },
  [STORE_PAGE_ID.games]: {
    title: 'Games: The Future of Web3 Play - GameFi, Metaverse, NFTs, & More',
    Icon: MultiColorGamepad,
    decorImage: {
      mobile: '/page-header/games-page-banner-decor-mobile.png',
      desktop: '/page-header/games-page-banner-decor-desktop.png',
    },
    breadcrumbs: [
      {
        label: 'Discover',
        pathname: '/',
      },
      {
        label: 'Games',
        pathname: '/games',
      },
    ],
  },
  [STORE_PAGE_ID.upcoming]: {
    title: 'Upcoming: Discover Apps and Games in Beta or Development',
    Icon: MultiColorUpcoming,
    decorImage: {
      mobile: '/page-header/upcoming-page-banner-decor-mobile.png',
      desktop: '/page-header/upcoming-page-banner-decor-desktop.png',
    },
    breadcrumbs: [
      {
        label: 'Discover',
        pathname: '/',
      },
      {
        label: 'Apps',
        pathname: '/apps',
      },
      {
        label: 'Upcoming',
        pathname: '/upcoming',
      },
    ],
  },
  'hot-offers': {
    title: 'Hot Offers: Exclusive Rewards, Giveaways and Deals for the Magic Store Community',
    icon: <Flame />,
    decorImage: {
      mobile: '/page-header/hot-offers-page-banner-decor-mobile.png',
      desktop: '/page-header/hot-offers-page-banner-decor-desktop.png',
    },
    breadcrumbs: [
      {
        label: 'Discover',
        pathname: '/',
      },
      {
        label: 'Hot Offers',
        pathname: '/hot-offers',
      },
    ],
  },
  'magic-board': {
    title: 'Exclusive PoW Hot Offers | MagicWall at MagicStore',
    icon: <Flame />,
    decorImage: {
      mobile: '/page-header/hot-offers-page-banner-decor-mobile.png',
      desktop: '/page-header/hot-offers-page-banner-decor-desktop.png',
    },
    breadcrumbs: [
      {
        label: 'Discover',
        pathname: '/',
      },
      {
        label: 'Magic Board',
        pathname: '/magic-board',
      },
    ],
  },
  perks: {
    title: 'Perks: Exclusive Web3 Deals Tailored Just for Magic Store Community',
    decorImage: {
      mobile: '/page-header/perks-page-banner-mobile.png',
      desktop: '/page-header/perks-page-banner.png',
    },
    Icon: MultiColorGift2,
    breadcrumbs: [
      {
        label: 'Discover',
        pathname: '/',
      },
      {
        label: 'Perks',
        pathname: '/perks',
      },
    ],
  },
  tge: {
    title: 'Magic Square Road to TGE in Collaboration with CoinList',
    decorImage: {
      mobile: '/page-header/tge-page-banner-decor-desktop.png',
      desktop: '/page-header/tge-page-banner-decor-desktop.png',
    },
    icon: (
      <ImageProxy
        alt=""
        src="/page-header/tge-page-banner-icon-desktop.png"
        width={407}
        height={368}
      />
    ),
    breadcrumbs: [
      {
        label: 'Discover',
        pathname: '/',
      },
      {
        label: 'Road to TGE Quests',
        pathname: `/stories/${roadToTgeQuests.id}`,
      },
    ],
  },
  'reward-pools': {
    title: 'Reward Pools',
    decorImage: {
      // TODO: update images
      mobile: '/page-header/tge-page-banner-decor-desktop.png',
      desktop: '/page-header/tge-page-banner-decor-desktop.png',
    },
    icon: (
      // TODO: update images
      <ImageProxy
        alt=""
        src="/page-header/tge-page-banner-icon-desktop.png"
        width={407}
        height={368}
      />
    ),
    breadcrumbs: [
      {
        label: 'Discover',
        pathname: '/',
      },
      {
        label: 'Reward Pools',
        pathname: `/reward-pools`,
      },
    ],
  },
  user: {
    breadcrumbs: [
      {
        label: 'Discover',
        pathname: '/',
      },
    ],
  },
};

interface PageHeaderProps extends Partial<Omit<BasePageHeaderProps, 'decorImage'>> {
  page: PageWithHeaderId;
  extendBreadcrumbs?: BreadcrumbsItem[];
}

const PageHeader = ({ page, extendBreadcrumbs, ...props }: PageHeaderProps) => {
  const pathname = usePathname();
  const { Icon, breadcrumbs, ...headerProps } = pageHeaderProps[page];
  const extendedBreadcrumbs = useMemo(
    () => [...(breadcrumbs || []), ...(extendBreadcrumbs || [])],
    [breadcrumbs, extendBreadcrumbs],
  );

  return (
    <BasePageHeader
      breadcrumbsCurrentPathname={pathname}
      breadcrumbs={extendedBreadcrumbs}
      icon={Icon && <Icon className="opacity-10" />}
      {...headerProps}
      {...props}
    />
  );
};

export default PageHeader;
