import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import NextLink from '@shared/common/components/NextLink';

export interface BreadcrumbsItem {
  label: string;
  pathname: string;
  isCurrent?: boolean;
}

export interface BreadcrumbsProps extends ComponentPropsWithoutRef<'nav'> {
  items: BreadcrumbsItem[];
  currentPathname?: string;
}

const Breadcrumbs = ({ className, currentPathname, items, ...props }: BreadcrumbsProps) => {
  return (
    <nav aria-label="Breadcrumb" className={classNames(className)} {...props}>
      <ol className="flex flex-wrap">
        {items.map((item, i) => {
          const isCurrent =
            item.isCurrent || (!!currentPathname && item.pathname === currentPathname);
          const isLast = i === items.length - 1;

          return (
            <li key={item.label} className="flex">
              {item.pathname && !isCurrent ? (
                <NextLink href={item.pathname} className="text-primary">
                  {item.label}
                </NextLink>
              ) : (
                <span className="text-neutral-500" aria-current={isCurrent && 'page'}>
                  {item.label}
                </span>
              )}

              {!isLast && (
                <span aria-hidden="true" className="text-neutral-500 mx-2">
                  ››
                </span>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
