import { Story } from '@shared/api/types/story';

export const roadToTgeQuests: Story = {
  id: 'coinlist-magicsquare-road-to-tge-quests',
  type: 'ZEALY',
  status: 'ACTIVE',
  metadata: {
    title: 'Magic Square Road to TGE with CoinList: 2.5M $SQR Prize Pool',
    description:
      "Enter Magic Square's exclusive Road to TGE campaign in collaboration with CoinList. Your chance to win a share of 2,500,000 $SQR Tokens!",
  },
};
